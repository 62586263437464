// 引入api
import { fun_PostAction } from "@/api";
import FileSaver from "file-saver";
import XLSX from "xlsx";
// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 查询表单字段
      form: {
        meetingType: null,
      },
      // 查询表单
      formSearchList: [
       /*  {
          type: "input",
          label: "参会者类型：",
          value: "meetingType",
          placeholder: "请选择"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "select",
          label: "角色：",
          value: "roleId",
          placeholder: "请选择",
          queryParams: {
            sqlId: "6515d115-1e37-423a-91d4-80a14fe3c494"
          }
        } */

      ],
      // 表头按钮组
      btnList: [
        {
          name: "日志导出",
          type: "primary",
          icon: "document-add"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "操作人",
          prop: "realName"
        },
        {
          label: "操作页面",
          prop: "operationPage"
        },
        {
          label: "操作时间",
          prop: "operationDate"
        },
        {
          label: "操作内容",
          prop: "operationContent"
        }
      ],
      // 表格数据
      tableData: [],
      // 分页
      reqPageData: {
        current: 1, // 页码
        size: 10, // 每页条数
      },
      total: 0, // 总条数
      multipleSelection: [],
      id:null
    };
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威 
     */
    async fun_GetList() {
      // 打开loading
      this.loading = true;
      let res = await fun_PostAction("operationLog/list", { 
        meetingId : this.$route.params.id,
        ...this.reqPageData
      });
      const { result, data, msg } = res.data;
      if (result) {
        // 解构data
        const { total, records } = data;
        // 回显table
        this.tableData = records;
        // 获取分页总条数
        this.total = total;
        // 关闭loading
        this.loading = false;
      } else {
        // 关闭loading
        this.loading = false;
        // 提示请求失败消息
        this.$message.error(msg);
      }


    },
    /**
     * 点击修改按钮
     * @author 滕威
     */
    fun_click() {
      /* 从表生成工作簿对象 */
      let fix = document.querySelector(".el-table__fixed");
      let wb;
      if (fix) {
        //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
        wb = XLSX.utils.table_to_book(
          document.querySelector("#qwesd").removeChild(fix)
        );
        document.querySelector("#qwesd").appendChild(fix);
      } else {
        wb = XLSX.utils.table_to_book(document.querySelector("#qwesd"));
      }
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "操作记录.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },

    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetList();
    },
  }
};
